@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,500;9..40,600;9..40,700&display=swap');


body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  margin: 0;
  overflow: hidden;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 650px) {
  .main__title{
    font-size: 30px;
  }
}

@media screen and (max-width:1450px) {
  .main__title {
      left: -0.5%;
  }
}