.App {
  text-align: left;
}


.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}


#image-track {
  display: flex;
  gap: 2vmin;
  position: absolute;
  left: 20%;
  top: 42%;
  transform: translate(0%, -50%);
  user-select: none;
  cursor: pointer;
}

#image-track>.image {
  width: 50vmin;
  height: 47vmin;
  object-fit: cover;
  object-position: 100% center;
  border-radius: 10px;
}

.main__container {
  justify-content: left;
  align-items: left;
  height: 100vh;
  width: 100vw;
}

.main__title {

  color: white;
  font-size: 70px;
  letter-spacing: -2px;
  transform: translate(0px, 20px);
  margin-left: 20px;
}


.main__link {
  color: white;
}

.spotify__logo {
  width: 50px;
}

.frame {
  bottom: 0;
  margin-left: 20px;
  position: absolute;
}

.main__email {
  bottom: 0;
  cursor: pointer;
  font-size: 20px;
  margin-right: 40px;
  color: white;
}

.noise::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  content: "";
  /* you can control opacity of noise  */
  opacity: .01;
  z-index: 1000;
  pointer-events: none;
  background: url(noise.gif);
}

.main__insta {
  width: 50px;
  margin: 40px;
  margin-right: -10px;
  cursor: pointer;
}

.main__facebook {
  width: 50px;
  margin: 40px;
  cursor: pointer;
}

.main__socials {
  display: flex;
  flex-direction: row;
}

.main__header {
  display: flex;
  justify-content: space-between;
}

.main__email__container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  margin: 10px;
  right: 0;
}

.main__gap {
  height: 80vh;
}

.fblogo {
  transform: translate(0px, -2px);
}

@media screen and (max-width:650px) {
  .main__title {
    white-space: nowrap;
  }

  .main__insta {
    transform: translate(-12px, 0px);
  }

  .fblogo {
    transform: translate(-30px, -2px);
  }


  #image-track>.image {
    width: 50vw;
    height: 40vh;
    object-fit: cover;
    object-position: 100% center;
    border-radius: 10px;
  }

  .frame {
    display: none;
  }

  .framemobile {
    bottom: 0;
    margin-left: 20px;
    position: absolute;
  }
}

@media screen and (min-width:650px) {
  .framemobile {
    display: none;
  }
}

@media screen and (max-width:955px) {
  .main__email__container {
    width: 100vw;
    justify-content: center;
    position: absolute;
    bottom: 0;
    margin: 10px;
  }

}